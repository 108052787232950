import React, { SetStateAction, useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ITradeAssetStats } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import { colors } from '../../const'
import ChartPage from '../../pages/ChartPage/ChartPage'

const TradeAssetBalanceToSwapVolumeRatio = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_trade_asset_stats', true, timeframe)) as ITradeAssetStats[]
			const pools = ['BTC.BTC', 'ETH.ETH', 'BSC.BNB', 'LTC.LTC', 'BCH.BCH', 'GAIA.ATOM', 'AVAX.AVAX', 'DOGE.DOGE', 'STABLECOIN', 'BASE.ETH', 'Others']
			const assetData: ITradeAssetStats[][] = []
			const seriesData: { x: number; y: number }[][] = []
			const setSeries: SetStateAction<IChart[] | undefined> = []
			pools.forEach((pool) => {
				assetData.push(chartData.filter((row) => row.ASSET_TYPE === pool))
			})
			assetData.forEach((asset) => {
				seriesData.push(
					asset.map((element: ITradeAssetStats) => {
						return { x: new Date(element.DAY).getTime() / 1000, y: element.TRADE_ASSET_TO_SWAP_VOLUME_RATIO }
					})
				)
			})
			let i = 0
			seriesData.forEach((series) => {
				setSeries.push({
					data: series,
					strokeWidth: 2,
					name: pools[i].split('-')[0].toUpperCase(),
					type: 'line',
					seriesGroup: '[ratio]',
					color: colors[i],
					unitSymbol: ' '
				})
				i++
			})

			setDataSeries(setSeries)
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Trade Asset Balance Value to Swap Volume Ratio"
				chartDesc="Aggregated value of trade asset balances divided by swap volume. STABLECOIN consists of all available stablecoin pools on THORChain."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="56b70e67-8ac9-4c9e-9569-852dfae88fbf"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default TradeAssetBalanceToSwapVolumeRatio
